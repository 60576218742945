<template>
	<b-toast :id="toastId" variant="warning" header-class="hide" solid>
		<template #toast-title>
			<div class="d-flex flex-grow-1 align-items-baseline">
				<b-img blank blank-color="#ff5555" class="mr-2" width="12" height="12"></b-img>
				<strong class="mr-auto">Notice!</strong>
				<small class="text-muted mr-2">42 seconds ago</small>
			</div>
		</template>
		This is the content of the toast.
		It is short and to the point.
	</b-toast>
</template>

<script>
export default {
  data () {
    return {
      toastId: 'success'
    }
  }
}
</script>

<style scoped>
.hide {
    display: none;
}
</style>
